<template>
  <div class="radio" :class="{'radio--warning':type == 'warning', 'radio--success':type == 'success', 'radio--cancel':type == 'cancel'}">
      
  </div>
</template>

<script>
export default {
    props:{
        type:{
            type:String,
            default:'',
        }
    }
}
</script>

<style lang="scss" scoped>
.radio{
    border-radius: 100%;
    height: 10px;
    width: 10px;
    border: 2px solid #109CF1;
    &--warning{
        border: 2px solid #FFB946;
    }
    &--success{
     border: 2px solid #2ED47A;
    }
    &--cancel{
      border: 2px solid #F7685B;
    }
}
</style>