<template>
  <div class="select-show">
    <span class="select-show__title">{{ $t("show") }} </span>
    <v-select
      v-model="nilai"
      hide-details
      :items="items"
      :label="label"
      item-text="dateLabel"
      item-value="dateValue"
      dense
      @change="$emit('changeDate', nilai)"
    ></v-select>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: "",
    },
    items: {
      type: Array,
      default: () => [],
    },
    value: {
      type: String,
      default: "",
    },
  },

  data: function () {
    return {
      nilai: this.value,
    };
  },

  watch: {
    value: function(new_val, old_val){
      if(old_val){
        this.nilai = new_val;
      }
      
    }
  },
};
</script>
<style lang="scss">
@import "~@/assets/scss/color.scss";
.select-show {
  .v-text-field {
    &.v-input {
      max-width: 95px;
      margin: 0;
    }
    .v-input {
      &__control {
        .v-input__slot {
          &::before {
            display: none;
          }
          &::after {
            display: none;
          }
          .v-select {
            &__selection {
              color: $main_2;
              font-weight: 400;
              font-size: 12px;
              line-height: 18px;
              text-transform: initial;
              margin: 0;
            }
          }
        }
      }
    }
  }
}
</style>
<style lang="scss" scoped>
//section select on record card
.select-show {
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  &__title {
    margin-right: 8px;
  }
}
</style>
