<template>
  <div>
    <apexchart
      height="360"
      ref="chart"
      :options="options"
      :series="series"
    ></apexchart>
  </div>
</template>

<script>
import moment from "moment";
import localStorageService from "@/service/localStorageService";
import ChartService from "@/service/chart_service.js";
export default {
  data: () => ({
    options: {
      chart: {
        id: "vuechartSummary",
        type: "line",
        toolbar: {
          show: true,
          offsetX: 0,
          offsetY: 0,
          tools: {
            download: false,
            selection: true,
            zoom: true,
            zoomin: true,
            zoomout: true,
            pan: true,
            reset: true | '<img src="/static/icons/reset.png" width="20">',
            customIcons: [],
          },
          autoSelected: "zoom",
        },
        locales: ChartService,
        defaultLocale: localStorageService.getItem("locale"),
      },
      xaxis: {
        tooltip: {
          enabled: false,
        },
        type: "datetime",
      },
      annotations: {},
      colors: ["#00a6b6"],
      tooltip: {
        x: {
          show: true,
        },
        y: {
          formatter: function (value) {
            return value;
          },
        },
        marker: {
          show: false,
        },
      },
      yaxis: [
        {
          labels: {
            formatter: function (val) {
              return parseInt(val);
            },
          },
        },
      ],
    },
    series: [
      {
        data: [],
      },
    ],
  }),
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    filter: {
      type: String,
      default: "",
    },
    activeRange: {
      type: String,
      default: "",
    },
  },
  watch: {
    data: function (newVal) {
      this.updateChartData(newVal);
    },
    activeRange: function () {
      this.updateChartData(this.data);
    },
  },
  created() {
    this.updateChartData(this.data);
  },
  methods: {
    updateChartData(data) {
      this.series = [{ name: "Total", data: [] }];
      const this_ = this;

      let xaxis = {
        tooltip: {
          enabled: false,
        },
        labels: {
          formatter: function (value) {
            return moment(value)
              .locale(this_.$i18n.locale)
              .format(
                this_.activeRange == "life_time" ||
                  this_.activeRange == "this_year"
                  ? "MMM yyyy"
                  : "DD MMM"
              );
          },
        },
      };

      let tooltip = {
        x: {
          show: true,
          formatter: function (value) {
            return moment(value)
              .locale(this_.$i18n.locale)
              .format(
                this_.activeRange == "life_time" ||
                  this_.activeRange == "this_year"
                  ? "MMM yyyy"
                  : "DD MMM"
              );
          },
        },
        y: {
          formatter: function (value) {
            return value;
          },
        },
        marker: {
          show: false,
        },
      };

      // switch (this.activeRange) {
      //   case "this_month":
      //     xaxis.max = new Date(moment().format("MM-DD-YYYY")).getTime();
      //     xaxis.min = new Date(
      //       moment().startOf("month").format("MM-DD-YYYY")
      //     ).getTime();
      //     break;
      //   case "last_month":
      //     xaxis.max = new Date(
      //       moment().subtract(1, "months").endOf("month").format("MM-DD-YYYY")
      //     ).getTime();
      //     xaxis.min = new Date(
      //       moment().subtract(1, "months").startOf("month").format("MM-DD-YYYY")
      //     ).getTime();
      //     break;
      //   case "last_3_month":
      //     xaxis.max = new Date(
      //       moment().subtract(1, "months").endOf("month").format("MM-DD-YYYY")
      //     ).getTime();
      //     xaxis.min = new Date(
      //       moment().subtract(3, "months").startOf("month").format("MM-DD-YYYY")
      //     ).getTime();
      //     break;
      //   case "this_year":
      //     xaxis.max = new Date(
      //       moment().endOf("year").format("MM-DD-YYYY")
      //     ).getTime();
      //     xaxis.min = new Date(
      //       moment().startOf("year").format("MM-DD-YYYY")
      //     ).getTime();
      //     tooltip.x.format = "MMM yyyy";
      //     break;
      //   default:
      //     tooltip.x.format = "MMM yyyy";
      //     break;
      // }

      if (data.length > 0) {
        this.series[0].data = data.map(res => {
          // if (this.filter == "life_time") {
          //   res.x = moment(res.date).format("MMM YYYY");
          // } else if (this.filter == "this_year") {
          //   res.x = moment(res.date).format("MMM");
          // } else {
          res.x = new Date(res.TestDate).getTime();
          //  moment(res.date).format("DD MMM yyyy");
          // }
          res.y = parseFloat(res.Result);
          return res;
        });
        if (this.$refs.chart != undefined) {
          this.$refs.chart.updateOptions({
            annotations: {},
          });
        }

        if (
          typeof data[0].MinimumValue == "number" &&
          typeof data[0].MaximumValue == "number"
        ) {
          let min = parseFloat(data[0].MinimumValue);
          let max = parseFloat(data[0].MaximumValue);
          if (min == max) {
            const annotations = {
              // yaxis: [
              //   {
              //     y: min,
              //     // y2: min + 1,
              //     // fillColor: "#ff0000",
              //     borderColor: "red",
              //     opacity: 1,
              //   },
              // ],
            };
            if (this.$refs.chart != undefined) {
              this.$refs.chart.updateOptions({
                annotations: annotations,
                xaxis: xaxis,
                tooltip: tooltip,
              });
            } else {
              this.options.annotations = annotations;
              this.options.xaxis = xaxis;
              this.options.tooltip = tooltip;
            }
          } else {
            const annotations = {
              // yaxis: [
              //   {
              //     y: min,
              //     y2: max,
              //     fillColor: "#00a6b6",
              //     opacity: 0.2,
              //   },
              //   {
              //     y: max,
              //     borderColor: "red",
              //     opacity: 1,
              //   },
              //   {
              //     y: min,
              //     borderColor: "red",
              //     opacity: 1,
              //   },
              // ],
            };
            if (this.$refs.chart != undefined) {
              this.$refs.chart.updateOptions({
                annotations: annotations,
                xaxis: xaxis,
                tooltip: tooltip,
              });
            } else {
              this.options.annotations = annotations;
              this.options.xaxis = xaxis;
              this.options.tooltip = tooltip;
            }
          }
        } else {
          const annotations = {
            // yaxis: [
            //   {
            //     y: null,
            //     borderColor: "#000",
            //     fillColor: "#00a6b6",
            //     opacity: 0.2,
            //   },
            // ],
          };
          if (this.$refs.chart != undefined) {
            this.$refs.chart.updateOptions({
              annotations: annotations,
              xaxis: xaxis,
              tooltip: tooltip,
            });
          } else {
            this.options.annotations = annotations;
            this.options.xaxis = xaxis;
            this.options.tooltip = tooltip;
          }
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
