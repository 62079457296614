<template>
  <div>
    <div class="corp-m">
        <div class="payment-info">
          <v-data-table
            :options.sync="options"
            :headers="headerTable"
            :items="revenueList"
            hide-default-footer
            :loading="myloadingvariable"
            class="table-officecare-company"
            :mobile-breakpoint="0"
            :items-per-page="-1"
          >
            <template v-slot:top>
              <div class="card__header">
                <v-row class="content-table-new">
                  <v-col cols="12" md="6" class="d-flex align-center">
                    <select-show
                      :items="DateFilterItems"
                      :value="dateFilterDefaultValue"
                      @changeDate="(nilai) => updateAllReport(nilai)"
                    />
                  </v-col>
                  <v-col
                    cols="9"
                    md="5"
                    class="d-flex align-center justify-end pr-0"
                  >
                    <v-text-field
                      class="searchField"
                      outlined
                      dense
                      hide-details
                      v-model="search"
                      append-icon="mdi-magnify"
                      :label="$t('search') + ' ...'"
                      single-line
                    ></v-text-field>
                  </v-col>
                  <v-col class="d-flex justify-end" cols="3" md="1">
                    <div >
                      <v-select
                        class="footerHide"
                        v-model="perPage"
                        :items="itemShowing"
                      ></v-select>
                    </div>
                  </v-col>
                </v-row>
              </div>
            </template>
            <template v-slot:item="{ item }">
              <tr>
                <td  valign="top" class="pt-2">
                  {{ item.sap_bp_id }}
                </td>
                <td  valign="top" class="pt-2 tb-wide">
                    {{ item.name }}
                </td>
                <td  valign="top" class="pt-2 tb-wide">{{ item.doctor_str }}</td>
                <td  valign="top" class="pt-2">{{ item.doctor_sip }}</td>
                <td  valign="top" class="pt-2">
                  {{ item.doctor_spesiality }}
                </td>
                <td  valign="top" class="pt-2">
                  <p class="list-table text-right" style="margin-bottom: 0px;">
                    {{ formater(item.fee_total) }}
                  </p>
                </td>
              </tr>
            </template>
            <template v-slot:footer>
              <div class="d-flex align-end justify-end pa-3">
                <p class="mb-0 mr-3" style="font-size: 12px; font-weight: 600">
                  Total :
                </p>
                <p class="mb-0" style="font-size: 12px; font-weight: 600">
                  {{ formater(totalRevenue) }}
                </p>
              </div>
              <v-divider></v-divider>
              <v-row
                class="d-flex flex align-center justify-space-between footer-payment"
                
              >
                <v-col cols="12" md="8">
                  <p class="footer-showing">
                    showing {{ itemShowStart }} to {{ itemShowStop }} of
                    {{ itemsTotal }} entries
                  </p>
                </v-col>
                <v-col cols="12" md="4" class="d-flex justify-end">
                  <v-pagination
                    class="pageStyle"
                    v-model="page"
                    :length="itemLength"
                    :total-visible="7"
                  >
                  </v-pagination>
                  <!-- :length="props.pagination.pageCount" -->
                </v-col>
              </v-row>
            </template>
          </v-data-table>
        </div>
      </div>
    <!-- <DialogOrderDetail
      v-if="dialog_order"
      :item="dataDialogOrder"
      :tampilkan="dialog_order"
      @toggleDialog="is_show => toggleDialogOrder(is_show)"
    /> -->
  </div>
</template>

<script>
import API from "@/service/api_service.js";
import Auth from "@/service/auth_service";
import moment from "moment";
import SelectShow from "@/components/v2/select/SelectShow.vue";

// import DialogOrderDetail from "@/components/v2/dialog/dialogOrderDetail.vue";

export default {
  components: {
    SelectShow,
  },
  data: () => ({
    page: 1,
    options: null,
    itemsTotal: 0,
    itemLength: 1,
    itemShowStart: 0,
    itemShowStop: 0,
    itemShowing: [
      { value: 25, text: "25" },
      { value: 50, text: "50" },
      { value: 70, text: "70" },
      { value: 100, text: "100" },
    ],
    perPage: 25,
    myloadingvariable: true,
    activeTab: 5,
    myDepoHistoryListCount: 0,
    footerProps: { "items-per-page-options": [25, 50, 70, -1] },
    search: "",
    revenueList: [],
    totalPaid: 0,
    totalUnpaid: 0,
    totalRevenue: 0,
    
    headerTable: [],
    dialog_order: false,
    dataDialogOrder: {
      order_id: null,
      list: [],
    },
    dateNow: moment().format("DD MMMM YYYY, dddd"),
    monthNow: moment().format("MMMM YYYY"),
    dateString_today: null,
    dateString_thisWeek: null,
    dateString_thisMonth: null,
    dateString_lastMonth: null,
    dateString_last3Month: null,
    dateString_thisYear: null,
    dateString_lifetime: null,
    DateFilterItems: [],
    dateFilterDefaultValue: "this_month",
    selectedDateNow: "",
    selectedMonthNow: "",
    thisWeek: [],
  }),
  watch: {
    page: function () {
      this.getDataDoctor();
    },
    perPage: function () {
      if (this.page != 1) {
        this.page = 1;
      } else {
        this.getDataDoctor();
      }
    },
    search: function () {
      this.page = 1;
      this.getDataDoctor();
    },
  },
  created() {
    this.DateFilterItems = [
      { dateValue: "today", dateLabel: this.$t("today") },
      { dateValue: "this_week", dateLabel: this.$t("this_week") },
      { dateValue: "this_month", dateLabel: this.$t("this_month") },
      { dateValue: "last_month", dateLabel: this.$t("last_month") },
      { dateValue: "last_3_month", dateLabel: this.$t("last_3_month") },
      { dateValue: "this_year", dateLabel: this.$t("this_year") },
      { dateValue: "life_time", dateLabel: this.$t("lifetime") },
    ];
    this.getDataDoctor();
  },
  async mounted() {
    this.headerTable = [
      {
        value: "sap_bp_id",
        text: this.$t("page.referral.bp_id_doctor"),
        align: "center",
      },
      {
        text: this.$t("page.referral.doctor_name"),
        value: "name",
        align: "center",
      },
      {
        text: this.$t("page.referral.str_number"),
        value: "doctor_str",
        align: "center",
      },
      {
        text: this.$t("page.referral.sip_hospital"),
        value: "doctor_sip",
        align: "center",
      },
      {
        text: this.$t("page.referral.spesialis"),
        value: "doctor_spesiality",
        align: "center",
      },
      {
        text: this.$t("page.profile.referral_fee"),
        value: "fee_total",
        align: "center",
      },
    ];
    this.getFilterDateStrings();
  },
  methods: {
    setPageMyDepoHistory(data) {
      this.myDepoHistoryListCount = data.pageStop - data.pageStart;
    },
    async getDataDoctor() {
      let show_by = this.dateFilterDefaultValue;
      try {
        this.myloadingvariable = true;
        // pagination showing start of entries
        let startPage = (this.page - 1) * this.perPage;
        let showPage = this.perPage;
        this.itemShowStart = startPage + 1;
        this.itemShowStop = startPage + showPage;

        var url = "";
        url =
          `${process.env.VUE_APP_API_TRUST}referral/get-fees-from-doctor?query=${this.search}&offset=${startPage}&per_page=${showPage}&show_by=` +
          show_by;

        const resp = await API.get(url, {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Auth.getToken()}`,
        });

        this.revenueList = resp.results;
        this.totalRevenue = 0;
        this.revenueList.forEach(data => {
          data.fee_total = data.fee_total || 0;
          this.totalRevenue += parseInt(data.fee_total);
        });

        this.itemsTotal = resp.total;
        this.itemLength = Math.ceil(this.itemsTotal / showPage);
        if (this.itemShowStop < 0) {
          this.itemShowStop = resp.total;
        }
        if (this.itemShowStop > resp.total) {
          this.itemShowStop = resp.total;
        }
        this.myloadingvariable = false;

      } catch (error) {
        this.revenueList = [];
      }
    },
    updateAllReport(e) {
      this.dateFilterDefaultValue = e;
      this.getDataDoctor();

      switch (e) {
        case "today":
          this.selectedDateNow = this.dateString_today;
          break;
        case "this_week":
          this.selectedDateNow = this.dateString_thisWeek;
          break;
        case "this_month":
          this.selectedDateNow = this.dateString_thisMonth;
          break;
        case "last_month":
          this.selectedDateNow = this.dateString_lastMonth;
          break;
        case "last_3_month":
          this.selectedDateNow = this.dateString_last3Month;
          break;
        case "this_year":
          this.selectedDateNow = this.dateString_thisYear;
          break;
        case "life_time":
          this.selectedDateNow = this.dateString_lifetime;
          break;
        default:
          this.selectedDateNow = this.dateString_thisWeek;
          break;
      }
    },

    getFilterDateStrings() {
      /* eslint-disable no-unused-vars */
      this.dateString_today = this.dateNow;
      let stringWeek = "";
      let week = this.thisWeek;
      let first_week = week[0];
      let last_week = week[week.length - 1];
      if (moment(first_week).format("MM") == moment(last_week).format("MM")) {
        stringWeek = `${moment(first_week).format("DD")} - ${moment(
          last_week
        ).format("DD MMMM YYYY")}`;
      } else {
        stringWeek = `${moment(first_week).format("DD MMMM")} - ${moment(
          last_week
        ).format("DD MMMM YYYY")}`;
      }
      this.dateString_thisWeek = stringWeek;
      this.dateString_thisMonth = moment().format("MMMM YYYY");
      this.dateString_lastMonth = moment()
        .subtract(1, "months")
        .format("MMMM YYYY");
      this.dateString_last3Month = `${moment()
        .subtract(3, "months")
        .format("MMM YYYY")} - ${moment().format("MMM YYYY")} `;
      this.dateString_thisYear = `This Year (${moment().format("YYYY")})`;
      this.dateString_lifetime = "Lifetime";

      this.selectedDateNow = this.dateString_thisWeek; // init pertama kali
      this.selectedMonthNow = this.monthNow;

      /* eslint-enable no-unused-vars */
    },
    // toggleDialogOrder(toggle) {
    //   this.dialog_order = toggle;
    // },
    // async openDialogOrder(item) {
    //   this.dataDialogOrder.order_id = item.order_id;
    //   this.dataDialogOrder.type_test = item.type;
    //   this.getOrderDetailPopup();
    //   this.dialog_order = true;
    // },
    // async getOrderDetailPopup() {
    //   let order_id = this.dataDialogOrder.order_id;
    
    //   try {
    //     const res = await API.get(
    //       `${process.env.VUE_APP_API_TRUST}lab-results/get-detail?order_id=` +
    //         order_id,
    //       {
    //         "Content-Type": "application/json",
    //         Authorization: `Bearer ${Auth.getToken()}`,
    //       }
    //     );
    //     this.dataDialogOrder.list = res.results;
    //   } catch (error) {
    //     console.log(error);
    //   }
    // },
    // currentDataItems() {
    //   if (this.activeTab == 1) {
    //     this.$router.push("/doctor/summary-report/referral");
    //   } else {
    //     this.$router.push("/doctor/summary-report/fee");
    //   }
    // },
    // getDateRange() {
    //   this.totalPaid = 0;
    //   this.totalUnpaid = 0;
    //   this.totalRevenue = 0;
    //   this.checkItemYear = false;
    //   this.checkItemMonth = false;

    //   API.get(`${process.env.VUE_APP_API_TRUST}referral/get-fees`, {
    //     "Content-Type": "application/json",
    //     Authorization: `Bearer ${Auth.getToken()}`,
    //   }).then(res => {
    //     this.start_date_filter = new Date(this.start_date).getTime();
    //     this.end_date_filter = new Date(this.end_date).getTime();

    //     const dateRangeFilter = res.results.filter(dateRangeData => {
    //       const newDateRangeData = new Date(dateRangeData.registration_date);
    //       const setDateRange = `${newDateRangeData.getFullYear()}-${(
    //         "0" +
    //         (newDateRangeData.getMonth() + 1)
    //       ).slice(-2)}-${("0" + newDateRangeData.getDate()).slice(-2)}`;
    //       const dateRangeGetTime = newDateRangeData.getTime();

    //       if (setDateRange == this.start_date) {
    //         const setNewDateStart = new Date(this.start_date);
    //         const setStartFilter = `${setNewDateStart.getFullYear()}-${
    //           setNewDateStart.getMonth() + 1
    //         }-${setNewDateStart.getDate() - 1}`;
    //         const startFilterGetTime = new Date(setStartFilter).getTime();

    //         return dateRangeGetTime > startFilterGetTime;
    //       } else if (setDateRange == this.end_date) {
    //         const setDateFilterRange = `${newDateRangeData.getFullYear()}-${
    //           newDateRangeData.getMonth() + 1
    //         }-${newDateRangeData.getDate() - 1}`;
    //         const DateNewDateFilter = new Date(setDateFilterRange).getTime();
    //         const endFilterGetTime = new Date(this.end_date).getTime();

    //         return DateNewDateFilter < endFilterGetTime;
    //       } else {
    //         return (
    //           dateRangeGetTime > this.start_date_filter &&
    //           dateRangeGetTime < this.end_date_filter
    //         );
    //       }
    //     });
    //     this.revenueList = dateRangeFilter;

    //     const filterNewUnpaid = this.revenueList.filter(dataNewUnpaid => {
    //       return dataNewUnpaid.claimed == false;
    //     });
    //     filterNewUnpaid.forEach(data => {
    //       this.totalUnpaid += parseInt(data.fee_total);
    //     });
    //     // this.totalUnpaid = filterNewUnpaid.length;

    //     const filterNewPaid = this.revenueList.filter(dataNewPaid => {
    //       return dataNewPaid.claimed == true;
    //     });
    //     filterNewPaid.forEach(data => {
    //       this.totalPaid += parseInt(data.fee_total);
    //     });
    //     // this.totalPaid = filterNewPaid.length;

    //     this.revenueList.forEach(data => {
    //       data.fee_total = data.fee_total || 0;
    //       this.totalRevenue += parseInt(data.fee_total);
    //     });
    //   });
    // },
    // formatDateNow(param) {
    //   if (!param) return null;
    //   const date = new Date(param);
    //   // return `${("0" + date.getDate()).slice(-2)}-${(
    //   //     "0" +
    //   //     (date.getMonth() + 1)
    //   // ).slice(-2)}-${date.getFullYear()}`;
    //   return moment(date).locale(this.$i18n.locale).format("DD-MMM-YYYY").toUpperCase();
    // },
    // changeFilterDate() {
    //   this.$refs.dialog.save(this.start_date);
    //   this.$refs.dialogend.save(this.end_date);
    //   this.getDateRange();
    //   this.modalStartDate = false;
    //   this.modalEndDate = false;
    // },
    formater(value) {
      // let val = (value / 1).toFixed(0).replace(".", ",");
      // return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      let angka = parseInt(value) || 0;
      let nilai = angka.toLocaleString("en-US", { minimumFractionDigits: 2 });
      return nilai;
    },
    formatDateStandard(value) {
      return moment(value).locale(this.$i18n.locale).format("DD-MMM-YYYY").toUpperCase();
    },

    formatDateStandardWithTime(value) {
      return moment(value).format("DD-MMM-YYYY HH:mm:ss").toUpperCase();
    },

    // getYearClick() {
    //   this.totalPaid = 0;
    //   this.totalUnpaid = 0;
    //   this.totalRevenue = 0;
    //   const dateNow = new Date();
    //   const setYearNow = `${dateNow.getFullYear()}`;

    //   API.get(`${process.env.VUE_APP_API_TRUST}referral/get-fees`, {
    //     "Content-Type": "application/json",
    //     Authorization: `Bearer ${Auth.getToken()}`,
    //   }).then(res => {
    //     const yearFilter = res.results.filter(setDataYear => {
    //       const newYearFilterData = new Date(setDataYear.registration_date);
    //       const setDateFormat = `${(
    //         "0" +
    //         (newYearFilterData.getMonth() + 1)
    //       ).slice(-2)}/${("0" + newYearFilterData.getDate()).slice(
    //         -2
    //       )}/${newYearFilterData.getFullYear()}`;
    //       const yearSlice = +setDateFormat.slice(-4);
    //       return setYearNow == yearSlice;
    //     });

    //     this.revenueList = yearFilter;
    //     this.checkItemYear = true;
    //     this.checkItemMonth = false;
    //     const filterNewUnpaid = this.revenueList.filter(dataNewUnpaid => {
    //       return dataNewUnpaid.claimed == false;
    //     });
    //     filterNewUnpaid.forEach(data => {
    //       this.totalUnpaid += parseInt(data.fee_total);
    //     });
    //     // this.totalUnpaid = filterNewUnpaid.length;

    //     const filterNewPaid = this.revenueList.filter(dataNewPaid => {
    //       return dataNewPaid.claimed == true;
    //     });
    //     filterNewPaid.forEach(data => {
    //       this.totalPaid += parseInt(data.fee_total);
    //     });
    //     // this.totalPaid = filterNewPaid.length;

    //     this.revenueList.forEach(data => {
    //       data.fee_total = data.fee_total || 0;
    //       this.totalRevenue += parseInt(data.fee_total);
    //     });
    //   });
    // },
    // getMonthClick() {
    //   this.totalPaid = 0;
    //   this.totalUnpaid = 0;
    //   this.totalRevenue = 0;
    //   const dateNow = new Date();
    //   const setMonthYearNow = `${("0" + (dateNow.getMonth() + 1)).slice(
    //     -2
    //   )}-${dateNow.getFullYear()}`;

    //   API.get(`${process.env.VUE_APP_API_TRUST}referral/get-fees`, {
    //     "Content-Type": "application/json",
    //     Authorization: `Bearer ${Auth.getToken()}`,
    //   }).then(res => {
    //     const monthYearFilter = res.results.filter(setDataMonthYear => {
    //       const newMonthYearFilterData = new Date(
    //         setDataMonthYear.registration_date
    //       );
    //       const setMonthYearFormat = `${(
    //         "0" +
    //         (newMonthYearFilterData.getMonth() + 1)
    //       ).slice(-2)}-${newMonthYearFilterData.getFullYear()}`;

    //       return setMonthYearNow == setMonthYearFormat;
    //     });

    //     this.revenueList = monthYearFilter;
    //     this.checkItemYear = false;
    //     this.checkItemMonth = true;

    //     const filterNewUnpaid = this.revenueList.filter(dataNewUnpaid => {
    //       return dataNewUnpaid.claimed == false;
    //     });
    //     filterNewUnpaid.forEach(data => {
    //       this.totalUnpaid += parseInt(data.fee_total);
    //     });
    //     // this.totalUnpaid = filterNewUnpaid.length;

    //     const filterNewPaid = this.revenueList.filter(dataNewPaid => {
    //       return dataNewPaid.claimed == true;
    //     });
    //     filterNewPaid.forEach(data => {
    //       this.totalPaid += parseInt(data.fee_total);
    //     });
    //     // this.totalPaid = filterNewPaid.length;

    //     this.revenueList.forEach(data => {
    //       data.fee_total = data.fee_total || 0;
    //       this.totalRevenue += parseInt(data.fee_total);
    //     });
    //   });
    // },
    // reloadPage() {
    //   window.location.href = `/dashboard/summary-report/fee`;
    // },
  },
};
</script>
<style>
.table-officecare-company thead {
  background: #ddd !important;
  font-weight: bold;
  color: #000 !important;
}
.table-officecare-company thead tr th {
  height: 38px !important;
  white-space: nowrap;
}
.table-officecare-company tbody tr td {
  font-size: 12px !important;
  height: 35px !important;
  white-space: nowrap;
}
.table-officecare-company tbody tr:hover {
  background-color: #eee !important;
}
</style>
<style>
/* Hide scrollbar for Chrome, Safari and Opera */
.table-stickoy .v-data-table__wrapper::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.table-stickoy .v-data-table__wrapper {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.super-dense > .v-data-table__wrapper > table > tbody > tr > td,
.super-dense > .v-data-table__wrapper > table > tbody > tr > th,
.super-dense > .v-data-table__wrapper > table > thead > tr > td,
.super-dense > .v-data-table__wrapper > table > thead > tr > th,
.super-dense > .v-data-table__wrapper > table > tfoot > tr > td {
  height: 35px !important;
}
.table-stickoy .v-data-table__wrapper {
  overflow-x: auto;
  overflow-y: auto;
  height: 500px !important;
}
.table-stickoy thead {
  position: sticky;
  inset-block-start: 0;
  top: 0;
  background: #ddd !important;
  font-weight: bold;
  color: #000 !important;
}
</style>
<style>
 
  .table-patient-list thead tr th {
  height: 38px !important;
  white-space: nowrap;
}
.table-patient-list tbody tr td {
  font-size: 12px !important;
  height: 35px !important;
  white-space: nowrap;
}
  /* @media (min-width: 600px) {
  .table-stickoy .v-data-table__wrapper {
    overflow: unset;
  }
  .table-stickoy thead {
    position: sticky;
    inset-block-start: 0;
    top: 0;
    background: #eeeeee !important;
    font-weight: bold;
    color: #000 !important;
  }
}
@media (max-width: 599px) {
  .table-stickoy .v-data-table__wrapper {
    overflow-x: auto;
    overflow-y: auto;
    height: 400px;
  }
  .table-stickoy thead tr {
    border-bottom: 0 !important;
    position: sticky;
    top: 0;
    z-index: 2;
  }
} */
</style>

<style lang="scss" scoped>
@import "@/assets/css/custom.scss";

.searchField {
  max-width: 500px;
}
.button--active {
  background: $main_3;
  color: white;
}
.button--outline {
  color: $main_3;
  border: 1px solid $main_3;
  background: white;
}
.button-box {
  box-sizing: border-box;
  border-radius: 10px;
  padding: 8px 16px;
  outline: none;
  text-transform: capitalize;
}
.card--result {
  background: $gray_3;
  padding: 18px;
  border-radius: 16px;
  width: 100%;
  // max-width: 300px;
  color: $gray_2;
  h4 {
    color: $gray_1;
    font-size: 20px;
  }
  p {
    margin: 0;
  }
  .card--result__item {
    padding: 6px 0;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $gray_2;
    &:nth-last-child(1) {
      border: none;
    }
  }
}

.status--paid {
  color: $green_1;
  font-weight: 600;
}
.status--unpaid {
  color: $red_1;
  font-weight: 600;
}
.tabs {
  background: #e5e5e5 !important;
}
.content-table {
  padding: 15px;
  @media screen and (max-width: 960px) {
    padding: 5px 18px 18px 18px;
  }
}
.content-table-new {
  padding: 5px 15px 5px 15px;
  @media screen and (max-width: 960px) {
    padding: 5px 18px 18px 18px;
  }
}
.total-fee {
  padding: 5px 18px 18px 18px;
}

.v-input {
  font-size: 12px !important;
}

@media screen and (max-width: 480px) {
  .content-table {
    padding: 0px !important;
  }
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 12px;
  vertical-align: baseline;
  padding-top: 6px;
  padding-bottom: 6px;
  height: 25px !important;
}

.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  height: 35px !important;
}
</style>
<style lang="scss">
.footerHide {
  background: none !important;
  align-items: center;
  width: 50px;
  font-size: 12px;
}
.footerHide > .v-data-footer__pagination {
  display: none !important;
}
.footerHide > .v-data-footer__icons-before {
  display: none !important;
}
.footerHide > .v-data-footer__icons-after {
  display: none !important;
}
.payment-info .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 12px;
  vertical-align: baseline;
  padding-top: 6px;
  padding-bottom: 6px;
}
.v-toolbar__title {
  font-size: 16px;
}
.footer-payment {
  margin: 0 auto !important;
}
.footer-showing {
  font-size: 12px !important;
  margin: 0 auto !important;
}




.footerHide > .v-text-field .v-input__prepend-inner,
.v-text-field .v-input__append-inner {
  padding-left: 0 !important;
}
</style>