<template>
  <div class="description" :class="{'description--warning':type == 'warning', 'description--success':type == 'success', 'description--cancel':type == 'cancel'}">
      
  </div>
</template>

<script>
export default {
    props:{
        type:{
            type:String,
            default:'',
        }
    }
}
</script>

<style lang="scss" scoped>
.description{
    border-radius: 100%;
    height: 10px;
    width: 10px;
    border: 2px solid #00A86B;
    &--warning{
        border: 2px solid #FFB946;
    }
    &--success{
     border: 2px solid #2ED47A;
    }
    &--cancel{
      border: 2px solid #F7685B;
    }
}
</style>