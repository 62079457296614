<template>
  <h4
    :class="{
      'heading-card--bold': bold,
      'heading-card--dark-blue': color == 'dark-blue',
      'heading-card--soft-blue': color == 'soft-blue',
    }"
  >
    {{ title }}
  </h4>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    bold: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/color.scss";
//title heading card patient summary & records
h4 {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: black;
  /* identical to box height */

  letter-spacing: 0.01em;
  &.heading-card {
    &--bold {
      font-weight: 700;
    }
    &--dark-blue {
      color: #233f78;
      font-weight: 700;
    }
    &--soft-blue {
      color: $main_2;
    }
  }
}
</style>
