export default [
  {
    name: "id",
    options: {
      months: [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ],
      shortMonths: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "Mei",
        "Jun",
        "Jul",
        "Agu",
        "Sep",
        "Okt",
        "Nov",
        "Des",
      ],
      days: ["Minggu", "Senin", "Selasa", "Rabu", "kamis", "Jumat", "Sabtu"],
      shortDays: ["Min", "Sen", "Sel", "Rab", "Kam", "Jum", "Sab"],
      toolbar: {
        exportToSVG: "Unduh SVG",
        exportToPNG: "Unduh PNG",
        exportToCSV: "Unduh CSV",
        menu: "Menu",
        selection: "Pilihan",
        selectionZoom: "Perbesar Pilihan",
        zoomIn: "Perbesar",
        zoomOut: "Perkecil",
        pan: "Geser",
        reset: "Atur Ulang Zoom",
      },
    },
  },
  {
    name: "en",
    options: {
      months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      shortMonths: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      days: [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ],
      shortDays: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
      toolbar: {
        exportToSVG: "Download SVG",
        exportToPNG: "Download PNG",
        exportToCSV: "Download CSV",
        menu: "Menu",
        selection: "Selection",
        selectionZoom: "Selection Zoom",
        zoomIn: "Zoom In",
        zoomOut: "Zoom Out",
        pan: "Panning",
        reset: "Reset Zoom",
      },
    },
  },
];
